/**
 * DLogin
 * @author Tevin
 */

import { Director, wrapper } from '@components/bases/Director';
import { Fetcher } from '@components/bases/Fetcher';
import { $fetchAccount } from '@fetchers/FAccount';
import { Tools } from '@components/common/Tools';

class DLogin extends Director {

    constructor(props) {
        super(props, 'DLogin');
        this.state = {
            initLogin: Fetcher.inDevMod ? { userName: 'abc123', password: '12345678' } : {},
            btnLoading: false,
        };
    }

    componentDidMount() {
        this.$landBusNet(
            {},
            {});
        /**
         * 自动登陆算法
         * 账号密码为 aixinranqi 02823001 时
         * 算法：password=md5(md5(02823001)+aixinranqi)
         * 结果：user=aixinranqi&password=e63c34441fe49a4debe92530d8e91be8
         */
        const userName = Tools.getUrlParam('user');
        const password = Tools.getUrlParam('password');
        if (userName) {
            // 立即移除账号密码
            this._deleteUrlParam();
            // 跳转登陆
            setTimeout(() => {
                this.onFinish({ userName, password, refer: 2 });
            }, 100);
        }
    }

    componentWillUnmount() {
        this.$logoutBusNet();
    }

    _deleteUrlParam() {
        const urlParam = window.location.search.substr(1);
        const urlParamArr = urlParam.split('&');
        const nextArr = [];
        for (let i = 0; i < urlParamArr.length; i++) {
            let param = urlParamArr[i].split('=');
            // 移除用户名密码
            if (param[0] !== 'user' && param[0] !== 'password') {
                nextArr.push(urlParamArr[i]);
            }
        }
        let nextUrl = window.location.href.split('?')[0];
        if (nextArr.length > 0) {
            nextUrl += '?' + nextArr.join('&');
        }
        window.history.replaceState({}, null, nextUrl);
    }

    onFinish(login) {
        if (this.state.btnLoading) {
            return;
        }
        this.$setState({
            btnLoading: true,
        });
        login.refer = login.refer || 1;
        $fetchAccount.login(login).then(res => {
            this.$setState({
                btnLoading: false,
            });
            if (!res) {
                return;
            }
            window.localStorage['AiSim@lastLoginTime'] = Date.now();
            let nextUrl = '';
            const curHref = window.location.href.split('#')[0];
            // 完整地址
            if (curHref.indexOf('index.html') > 0) {
                nextUrl = window.location.href.replace('index.html', 'home.html');
            }
            // 没有 index.html 的简化地址
            else {
                let domain = '';
                let search = '';
                if (curHref.indexOf('?') > 0) {
                    domain = curHref.split('?')[0];
                    search = '?' + curHref.split('?')[1];
                } else {
                    domain = curHref;
                }
                domain = /\/$/.test(domain) ? domain : (domain + '/');
                nextUrl = domain + 'home.html' + search;
            }
            // 补充时间戳，每次登陆时强制更新html
            if (nextUrl.indexOf('?') > 0) {
                // 已有时间戳
                if (nextUrl.indexOf('t=') > 0) {
                    nextUrl = nextUrl.replace(/t=[0-9]*(&|$)/, 't=' + Date.now() + '$1');
                }
                // 没有
                else {
                    nextUrl += '&t=' + Date.now();
                }
            } else {
                nextUrl += '?t=' + Date.now();
            }
            window.location.href = nextUrl;
        });
    }

}

export const connect = wrapper(DLogin);