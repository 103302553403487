/**
 * surface Login
 * @author Tevin
 */

import React from 'react';
import { Layout, Form, Input, Button } from 'antd';
import { UserOutlined, UnlockOutlined } from '@ant-design/icons';
import { connect } from '@directors/login/DLogin';

class Login extends React.Component {
    componentDidMount() {
        this._clearLocalStorage();
    }

    _clearLocalStorage() {
        window.localStorage.removeItem('AiSim@tableFilterCD');
        window.localStorage.removeItem('AiSim@tableFilterDatas');
        window.localStorage.removeItem('AiSim@orderAppointAllStore');
        window.localStorage.removeItem('AiSim@MessageNotice');
    }

    _renderLogin() {
        return (
            <Form
                name="basic"
                initialValues={this.props.initLogin}
                onFinish={evt => this.props.onFinish(evt)}
            >
                <Form.Item
                    name="userName"
                    rules={[
                        {
                            required: true,
                            message: '用户名不能为空!',
                        },
                    ]}
                >
                    <Input
                        size="large"
                        prefix={<UserOutlined />}
                        placeholder="请输入用户名"
                    />
                </Form.Item>
                <Form.Item
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: '密码不能为空!',
                        },
                    ]}
                >
                    <Input.Password
                        size="large"
                        prefix={<UnlockOutlined />}
                        placeholder="请输入密码"
                    />
                </Form.Item>
                <Form.Item>
                    <Button
                        type="primary"
                        size="large"
                        htmlType="submit"
                        loading={this.props.btnLoading}
                        block
                    >
                        登录
                    </Button>
                </Form.Item>
            </Form>
        );
    }

    render() {
        return (
            <Layout.Content>
                <div className="login">
                    <div className="login-inner">
                        <img
                            className="logo"
                            src="/static/manager/images/login-logo.png"
                            alt="logo"
                        />
                        {this._renderLogin()}
                    </div>
                </div>
                <div className="footer">
                    <a
                        href="http://beian.miit.gov.cn/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        粤ICP备16120738号-1
                    </a>
                    <span>|</span>@
                    <a
                        href="https://www.aisim.cn"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        AiSim.cn
                    </a>{' '}
                    深圳市艾信网络能源有限公司 Copyright © 2018 All Rights Reserved.
                </div>
            </Layout.Content>
        );
    }
}

export default connect(Login);
